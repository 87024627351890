import React, { useState } from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import route from "ziggy-js";
import { ModelBlock, Simulation } from "../../models";
import { plus } from "react-icons-kit/feather/plus";
import { PrimaryButtonOutline } from "../../components/admin-components";
import { SortableModelBlockTree } from "./SortableModelBlockTree";
import { SapienInertia } from "@/inertia-utils/hooks";
import { ModalContainer } from "../../modules/shared";
import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

export const InteractiveBlockList = (props: {
    simulation: Simulation;
    modelBlocks: ModelBlock[];
    simulations: Partial<Simulation>[];
}) => {
    const { simulation, modelBlocks, simulations } = props;

    const [clonedBlock, setClonedBlock] = useState<ModelBlock>();
    const [isBusy, setIsBusy] = useState(false);

    return (
        <>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-3">
                <div>
                    <div className="mb-4">
                        <div className="text-l mb-2 font-bold">
                            Interactive Blocks
                        </div>
                        {modelBlocks?.map((block, i) => (
                            <div
                                key={block.id}
                                className="flex w-full items-center justify-between p-2 hover:bg-neutral-800"
                            >
                                <InertiaLink
                                    href={route(
                                        "admin.projects.model-builder.interactive-detail",
                                        {
                                            simulationSlug: simulation.slug,
                                            type: "interactive",
                                            interactiveBlockId: block.id,
                                        },
                                    )}
                                >
                                    <div className="px-3 font-light hover:underline hover:underline-offset-2">
                                        {block.label}
                                    </div>
                                </InertiaLink>
                                <div className="flex items-center justify-center">
                                    <button
                                        onClick={() => {
                                            setClonedBlock(block);
                                        }}
                                        className="inline-flex items-center justify-center rounded-full bg-blue-700 px-5 py-2.5
                                            text-center text-sm font-medium text-white transition-all hover:bg-blue-800
                                            focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:cursor-not-allowed
                                            disabled:opacity-75"
                                        disabled={isBusy}
                                    >
                                        <DocumentDuplicateIcon className="h-4 w-4" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="">
                        <PrimaryButtonOutline
                            text={"Create Interactive Block"}
                            icon={plus}
                            handler={() =>
                                SapienInertia.post(
                                    "admin.model-blocks.store",
                                    {
                                        label: "Interactive Block",
                                        simulation_id: simulation.id,
                                        weight: modelBlocks?.length || 0,
                                        is_interactive: true,
                                    },
                                    {},
                                    {
                                        preserveState: true,
                                        preserveScroll: true,
                                    },
                                )
                            }
                        />
                    </div>
                </div>
                <div>
                    <div className="text-l mb-4 font-bold">Organization</div>
                    <SortableModelBlockTree
                        modelBlocks={modelBlocks?.reduce(
                            (map, block) => ({ ...map, [block.id]: block }),
                            {},
                        )}
                        modelBlockArray={modelBlocks as unknown as ModelBlock[]}
                        preventMixedLevelSort={true}
                    />
                </div>
                <div></div>
            </div>
            <ModalContainer
                isModalOpen={!!clonedBlock}
                setIsModalOpen={() => setClonedBlock(undefined)}
                size={"m"}
                classNames={"z-100"}
                backgroundColor="#111928"
            >
                <div className="p-6 text-white">
                    {!!clonedBlock && (
                        <div className="flex flex-col space-y-4">
                            <div>Clone Model Block — {clonedBlock.label}</div>
                            <div>
                                <label className="block text-sm font-medium">
                                    Label
                                </label>
                                <div className="mt-2">
                                    <input
                                        onChange={(e) =>
                                            setClonedBlock({
                                                ...clonedBlock,
                                                label: e.target.value,
                                            } as ModelBlock)
                                        }
                                        value={clonedBlock.label}
                                        type="text"
                                        className={`block w-full max-w-3xl rounded-md border-gray-300 bg-white/10 text-gray-200
                                            focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm`}
                                        placeholder="Block Title"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium">
                                    Simulation
                                </label>
                                <div className="mt-2">
                                    <select
                                        onChange={(e) =>
                                            setClonedBlock({
                                                ...clonedBlock,
                                                simulation_id: e.target.value,
                                            } as ModelBlock)
                                        }
                                        value={clonedBlock.simulation_id}
                                        id="prompt_scope"
                                        name="prompt_scope"
                                        className="relative mt-1 block w-full rounded-md border-gray-300 bg-white/10 text-gray-200
                                            focus:z-10 focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8]
                                            sm:text-sm"
                                    >
                                        <option value={""} disabled>
                                            Simulation
                                        </option>
                                        {simulations?.map((sim) => (
                                            <option value={sim.id} key={sim.id}>
                                                {sim.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="grid gap-6 border-t border-[#374151] px-6 pt-6 md:grid-cols-2">
                                <div className="flex items-center justify-center">
                                    <button
                                        type="button"
                                        className="inline-flex w-full items-center justify-center rounded-full px-5 py-2.5
                                            text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none
                                            focus:ring-2 focus:ring-blue-300"
                                        disabled={isBusy}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setClonedBlock(undefined);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                <div className="flex items-center justify-center">
                                    <button
                                        onClick={() => {
                                            setIsBusy(true);
                                            SapienInertia.post(
                                                "admin.model-blocks.clone",
                                                {
                                                    simulation_id:
                                                        clonedBlock.simulation_id,
                                                    model_block_id:
                                                        clonedBlock.id,
                                                    label: clonedBlock.label,
                                                },
                                            );
                                            setIsBusy(false);
                                            setClonedBlock(undefined);
                                        }}
                                        className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5
                                            py-2.5 text-center text-sm font-medium text-white transition-all
                                            hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300
                                            disabled:cursor-not-allowed disabled:opacity-75"
                                        disabled={isBusy}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </ModalContainer>
        </>
    );
};
